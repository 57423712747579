import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

export type TooltipPositioningScheme = 'topFullWidth' | 'none';

interface ClientRect {
  bottom: number;
  readonly height: number;
  left: number;
  right: number;
  top: number;
  readonly width: number;
}

@Component({
  selector: 'app-tooltip',
  styleUrls: ['./tool-tip.component.scss'],
  templateUrl: './tool-tip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TooltipComponent {

  @Input() positioningScheme: TooltipPositioningScheme = 'none';
  @Input() text = '';
  @Input() clientRect: ClientRect;
}
