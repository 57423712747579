<div class="tooltip tooltip-bottom-full-width" *ngIf="positioningScheme==='topFullWidth'; else noPositioning" @tooltip
     [ngStyle]="{left: clientRect.left + 'px', bottom: clientRect.top + 'px', width: clientRect.width + 'px'}">
    {{ text }}
</div>

<ng-template #noPositioning>
    <div class="tooltip tooltip-standard" #standardTooltip @tooltip
         [ngStyle]="{left: clientRect.left + 'px', top: clientRect.top - (standardTooltip.clientHeight + 20) + 'px'}">
        {{ text + standardTooltip.offsetHeight }}
    </div>
</ng-template>
