<!--Google Docs URL parameters:-->
<!--rm=minimal-->
<!--rm=full-->
<!--rm=embedded-->
<!--rm=demo-->
<!--rm=(render mode)-->
<!--ui=2 (select the interface version)-->
<!--chrome=false (full screen mode)-->
<!--gid=24 (Whis sheet you want to display)-->
<!--widget=false-->
<!--single=true-->
<!--range=A2:AA26 Output=html-->
<!--widget=false-->
<!--width=(width)-->
<!--height=(height)-->
<!--frameborder=(size of border)-->
<!--q=(Whatever) Search Query-->
<!--viewer?-->
<!--start=-->
<!--channel=-->
<!--ibd=-->
<!--client=-->
<div class="iframe-wrapper" *ngIf="cachedDocuments.length > 0"
     [ngStyle]="{'visibility': document ? 'visible': 'hidden', width: screenWidth + 'px', height: screenHeight + 'px'}">
<!--    <div class="debug-loading-status" *ngIf="debugService.isDebug">-->
<!--        Loading: {{loading}}-->
<!--        <pre>-->
<!--            {{progressData|json}}-->
<!--        </pre>-->
<!--        <pre>-->
<!--            {{error|json}}-->
<!--        </pre>-->
<!--        {{screenWidth + ' x ' + screenHeight}}-->
<!--    </div>-->


    <div class="loading-area" *ngIf="loading">
        <div>
            <ion-text color="light" class=" loading-text ion-text-center">
                Loading document<br/>
            </ion-text>
        <div>
            <ion-progress-bar color="light" type="indeterminate"></ion-progress-bar>
        </div>
        </div>
        <!--    <img class="vertue-logo" src="/vertue-workspace/projects/vertue-student/src/assets/vertue_logo_large.png">-->
        <!--    <img class="vertue-logo-text" src="/vertue-workspace/projects/vertue-student/src/assets/vertue_logo_text.png">-->

    </div>
    <div style="position: relative;"
         [ngStyle]="{width: screenWidth + 'px', height: screenHeight + 'px'}">
<!---->
        <pdf-viewer
                *ngFor="let cachedDocument of cachedDocuments"
                    [src]="cachedDocument.url"
                    [zoom]="1"
                    [zoom-scale]="'page-fit'"
                    [original-size]="false"
                    [show-all]="false"
                    [show-borders]="false"
                    [autoresize]="true"
                    [ngStyle]="{'visibility': (document && cachedDocument.studentDocument.url === document.url) ? 'visible': 'hidden',
                                'width': screenWidth + 'px',
                                'max-width': screenWidth + 'px',
                                'height': screenHeight + 'px',
                                'max-height': screenHeight + 'px',
                                'display': 'block'}"
                    [(page)]="cachedDocument.studentDocument.pageNumber"
                    (on-progress)="onProgress($event)"
                    (on-error)="onError($event)"
                    (after-load-complete)="afterLoadComplete($event, cachedDocument)"


        ></pdf-viewer>
    </div>
</div>
