
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {AuthService} from 'vertue-common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {

  }
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.authToken}`,
        },
      });

      return next.handle(req);
    } else {
      return this.authService.getAuthToken$().pipe(concatMap((token) => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });

        return next.handle(req);
      }));
    }
  }
}
