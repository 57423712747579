// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth0Audience: 'https://api.vertue.com/coach/',
  auth0Domain: 'vertue-dev.auth0.com',
  auth0ClientIdWeb: '8M34EP05mqhyhJ0ep7OkhojjSoFzNtHo',
  auth0ClientIdNative: 'jM24iKwZUmMqyu7ZLpGa24LMArkJdZSC',
  vertueApiUrl: 'https://api-dev.vertue.com/v1',
  vertueRedirectPath: '/lobby'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
