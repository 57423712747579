<ion-header translucent>
    <ion-toolbar>
        <ion-title>Service Error</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="padding-right-medium padding-left-medium">
        <p>
            Ooops! We encountered an unexpected error while processing a request. Please try again later. If the problem
            persists, please contact your system administrator.
        </p>

        <ng-container *ngIf="errorList[currentErrorIndex]">
            <div>
                ({{errorList[currentErrorIndex]?.serviceResponse?.status}}) {{errorList[currentErrorIndex].url}}
            </div>
            <div>
                {{errorList[currentErrorIndex]?.serviceResponse?.error?.message}}
            </div>
        </ng-container>

        <p>
            <strong>Event id: </strong>
        </p>

        <a class="pull-right link" (click)="showDetails = !showDetails">{{showDetails ? "Hide" : "Show"}} Error Details</a>
        <div style="clear:both"></div>
        <div [hidden]="!showDetails">
            <pre *ngIf="currentErrorIndex >= 0"><code>{{errorList[currentErrorIndex] | json}}</code></pre>
        </div>
    </div>


</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end" class="padding-small">
            <ion-button class="primary-outline medium" (click)="onDismissModal()">Contact Support</ion-button>
            <ion-button class="primary medium" (click)="onDismissModal()">
                Cancel
            </ion-button>


        </ion-buttons>
    </ion-toolbar>

</ion-footer>
