import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipComponent } from './components/tool-tip/tool-tip.component';
import {VertueCommonModule} from 'vertue-common';
import {VertueServiceConfig} from 'vertue-common';
import {AuthInterceptor} from './services/auth-inteceptor.service';
import {ErrorHandlerDialogComponent} from '../../../vertue-common/src/lib/components/error-handler-dialog/error-handler-dialog.component';


@NgModule({
    declarations: [
      AppComponent,
        ErrorHandlerDialogComponent,
        TooltipComponent,

    ],
    entryComponents: [
      TooltipComponent
    ],
    imports: [
      BrowserModule,
        BrowserAnimationsModule,
        VertueCommonModule.forRoot(environment as VertueServiceConfig),
        OverlayModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})],
    providers: [
        StatusBar,
        SplashScreen,
        AndroidPermissions,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true,
        },
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
