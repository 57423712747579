import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'lobby',
    loadChildren: () => import('./pages/specialist-lobby/specialist-lobby.module').then( m => m.SpecialistLobbyPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/specialist-home/specialist-home.module').then( m => m.SpecialistHomePageModule)
  },
  {
    path: 'login/callback',
    redirectTo: '/lobby',
    pathMatch: 'full'
  },
  {
    path: 'signedout',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: '',
    redirectTo: '/lobby',
    pathMatch: 'prefix'
  }, {
    path: 'signedout',
    redirectTo: '/sign-up',
    pathMatch: 'prefix'
  }
  // {
  //   canActivate: [
  //     AuthGuard
  //   ],
  //   path: 'login/callback',
  //   loadChildren: () => import('./pages/login-page/login-page.module').then( m => m.LoginPagePageModule)
  // },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
